/* Generated by Font Squirrel (http://www.fontsquirrel.com) on January 26, 2013 07:16:15 AM America/New_York */



@font-face {
    font-family: 'LatoBlackItalic';
    src: url('../fonts/lato/Lato-BlaIta-webfont.eot');
    src: url('../fonts/lato/Lato-BlaIta-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-BlaIta-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-BlaIta-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-BlaIta-webfont.svg#LatoBlackItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoBlack';
    src: url('../fonts/lato/Lato-Bla-webfont.eot');
    src: url('../fonts/lato/Lato-Bla-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Bla-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-Bla-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-Bla-webfont.svg#LatoBlack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoBoldItalic';
    src: url('../fonts/lato/Lato-BolIta-webfont.eot');
    src: url('../fonts/lato/Lato-BolIta-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-BolIta-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-BolIta-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-BolIta-webfont.svg#LatoBoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoBold';
    src: url('../fonts/lato/Lato-Bol-webfont.eot');
    src: url('../fonts/lato/Lato-Bol-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Bol-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-Bol-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-Bol-webfont.svg#LatoBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoItalic';
    src: url('../fonts/lato/Lato-RegIta-webfont.eot');
    src: url('../fonts/lato/Lato-RegIta-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-RegIta-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-RegIta-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-RegIta-webfont.svg#LatoItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoRegular';
    src: url('../fonts/lato/Lato-Reg-webfont.eot');
    src: url('../fonts/lato/Lato-Reg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Reg-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-Reg-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-Reg-webfont.svg#LatoRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoLightItalic';
    src: url('../fonts/lato/Lato-LigIta-webfont.eot');
    src: url('../fonts/lato/Lato-LigIta-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-LigIta-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-LigIta-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-LigIta-webfont.svg#LatoLightItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoLight';
    src: url('../fonts/lato/Lato-Lig-webfont.eot');
    src: url('../fonts/lato/Lato-Lig-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Lig-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-Lig-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-Lig-webfont.svg#LatoLight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoHairlineItalic';
    src: url('../fonts/lato/Lato-HaiIta-webfont.eot');
    src: url('../fonts/lato/Lato-HaiIta-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-HaiIta-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-HaiIta-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-HaiIta-webfont.svg#LatoHairlineItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'LatoHairline';
    src: url('../fonts/lato/Lato-Hai-webfont.eot');
    src: url('../fonts/lato/Lato-Hai-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Hai-webfont.woff') format('woff'),
         url('../fonts/lato/Lato-Hai-webfont.ttf') format('truetype'),
         url('../fonts/lato/Lato-Hai-webfont.svg#LatoHairline') format('svg');
    font-weight: normal;
    font-style: normal;

}

